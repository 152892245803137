

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { useParams } from "react-router-dom";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import apiKey from "../../../config/apiKey";
// import Modal from "react-modal";
// import Invoice from "../../../components/Invoice";

// const student = JSON.parse(localStorage.getItem("edo-student"));

//  // To prevent accessibility warnings

// function PaymentPage() {
//   const { id } = useParams(); // Extract class_id from the URL
//   const [classDetails, setClassDetails] = useState(null);
//   const [activeTab, setActiveTab] = useState("mobileAccount");
//   const [isLoading, setIsLoading] = useState(false);
//   const [showOTPModal, setShowOTPModal] = useState(false);
//   const [showTransactionModal, setShowTransactionModal] = useState(false); // New state for transaction modal
//   const [banks, setBanks] = useState([]);
//   const [message, setMessage] = useState("");
//   const [error, setError] = useState("");
//   const [paymentMethod, setPaymentMethod] = useState(''); 
//   const userId = student ? student.student.id : "";
//   const token = student ? student.token : "";

//   const [mobileAccountData, setMobileAccountData] = useState({
//     class_id: "",
//     subject_id: "",
//     channel_id: 8, // Default to Easypaisa
//     item: "Course Fee",
//     amount: "",
//     msisdn: "",
//     email: "",
//   });

//   const [bankData, setBankData] = useState({
//     class_id: "",
//     subject_id: "",
//     bankCode: "",
//     description: "Test Transaction",
//     item: "Course Fee",
//     amount: "",
//     msisdn: "",
//     cnic: "",
//     email: "",
//     billReferenceNo: "",
//     consumerName: "",
//     accountNo: "",
//   });

//   const [transferData, setTransferData] = useState({
//     class_id: "",
//     subject_id: "",
//     transactionId: "",
//     orderId: "",
//     billReferenceNo: "",
//     transactionReferenceNo: "",
//     retreivalReferenceNo: "",
//     otp: "",
//   });

  // useEffect(() => {
  //   const fetchClassDetails = async () => {
  //     setIsLoading(true);
  //     try {
       
  //       const response = await axios.get(
  //         `${apiKey}/api/edolutions/getclassdetails/${id}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );
  //       const data = response.data.class_details;
  //       setClassDetails(data);
  //       console.log(data )
  //       //                                                                                                                                          

  //       setMobileAccountData((prev) => ({
  //         ...prev,
  //         class_id: data.id,
  //         subject_id: data.subject_id,
  //         amount: 10,
  //         email: data.email,
  //       }));
  //       setBankData((prev) => ({
  //         ...prev,
  //         student_id: data.student_id,
  //         class_id: data.id,
  //         subject_id: data.subject_id,
  //         amount: 10,
  //         email: data.email,
  //         consumerName: data.student_name,
  //         billReferenceNo: `Bill-${data.id}`,
  //       }));
  //       setTransferData((prev) => ({
  //         ...prev,
  //         student_id: data.student_id,
  //         class_id: data.id,
  //         subject_id: data.subject_id,
  //       }));
  //       toast.success("Class details loaded successfully.", {
  //         position: "bottom-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //     } catch (error) {
  //       toast.error("Failed to fetch class details. Please try again.", {
  //         position: "bottom-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchClassDetails();
  // }, [id, token]);

//   useEffect(() => {
//     const fetchBanks = async () => {
//         //                                                                                                                                          
//       setIsLoading(true);
//       try {
//         const response = await axios.get(
//           `${apiKey}/api/edolutions/banks`,
//           {
       
//           }
//         );
        
//         setBanks(response.data.data);
//       } catch (error) {
//         toast.error("Failed to fetch bank data. Please try again.", {
//           position: "bottom-right",
//           autoClose: 5000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "colored",
//         });
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchBanks();
//   }, [student?.token]);

//   const handleTabChange = (tab) => {
//     setActiveTab(tab);
//   };

//   const handleMobileAccountChange = (e) => {
//     setMobileAccountData({
//       ...mobileAccountData,
//       [e.target.name]: e.target.value,
//     });
//   };

//   const handleBankChange = (e) => {
//     setBankData({ ...bankData, [e.target.name]: e.target.value });
//   };

//   const handleTransferChange = (e) => {
//     setTransferData({ ...transferData, [e.target.name]: e.target.value });
//   };

//   const handleMobileAccountSubmit = async (e) => {
//     e.preventDefault();
//     setIsLoading(true);

//     try {
//       const response = await axios.post(
//         `${apiKey}/api/edolutions/student/ewallet-payment`,
//         mobileAccountData,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       toast.success("Payment successful! Check your email for confirmation.", {
//         position: "bottom-right",
//         autoClose: 5000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "colored",
//       });
//       setPaymentMethod('Mobile Account');
   
//       setShowTransactionModal(true); // Show transaction modal on success
//     } catch (error) {
//       toast.error("Failed to process mobile account payment. Please try again.", {
//         position: "bottom-right",
//         autoClose: 5000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "colored",
//       });
    
//     } finally {
//       setIsLoading(false);
//     }
//   };
//   const handleBankOTPSubmit = async (e) => {
//     e.preventDefault();
//     setIsLoading(true);

//     try {
//       const response = await axios.post(
//         `${apiKey}/api/edolutions/bank/otp`,
//         bankData,
//         {
         
//         }
//       );

//       const bankResponse = response.data;
//       const responsePayload = JSON.parse(bankResponse.response_payload);
//       const requestPayload = JSON.parse(bankResponse.request_payload);

//       if (responsePayload && responsePayload.status === "success") {
//         setTransferData({
//           ...transferData,
//           transactionId: responsePayload.transactionId,
//           orderId: responsePayload.orderId,
//           billReferenceNo: requestPayload.billReferenceNo,
//           transactionReferenceNo: responsePayload.transactionReferenceNo,
//           retreivalReferenceNo: responsePayload.retreivalReferenceNo,
//         });
//         setShowOTPModal(true);
//         toast.success("OTP sent successfully. Please check your device.", {
//           position: "bottom-right",
//           autoClose: 5000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "colored",
//         });
//       } else {
//         toast.error("Transaction was not successful.", {
//           position: "bottom-right",
//           autoClose: 5000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "colored",
//         });
//       }
//     } catch (error) {
//       toast.error("Failed to initiate bank transfer OTP. Please try again.", {
//         position: "bottom-right",
//         autoClose: 5000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "colored",
//       });
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleBankTransferSubmit = async (e) => {
//     e.preventDefault();
//     setIsLoading(true);

//     try {
//       const response = await axios.post(
//         `${apiKey}/api/edolutions/bank/transfer`,
//         transferData,
//         {
         
//         }
//       );
//       toast.success("Bank transfer completed successfully!", {
//         position: "bottom-right",
//         autoClose: 5000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "colored",
//       });
//       setShowOTPModal(false);
//       setPaymentMethod('Bank Payment');
   
//       setShowTransactionModal(true);// Show transaction modal on success
//     } catch (error) {
//       toast.error("Failed to complete bank transfer. Please try again.", {
//         position: "bottom-right",
//         autoClose: 5000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "colored",
//       });
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <div className="flex items-center xl:p-5 text-center sm:p-0 justify-center">
//       <div className="w-full mx-auto border rounded-3xl shadow-xl p-5">
//         <h1 className="text-3xl font-bold text-center text-[#72dad0] mb-8">
            
//           Payment Portal
//         </h1>

//         {isLoading && (
//            <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-200 bg-opacity-5 backdrop-blur-sm z-50">
//            <div className="w-12 h-12 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-[#2DD0A5]"></div>
//            <p className="mt-4 text-lg font-semibold text-[#2DD0A5]">
//              Edolution...
//            </p>
//          </div>
//         )}
//         {message && (
//           <div className="text-center mb-4 text-green-500">{message}</div>
//         )}
//         {error && <div className="text-center mb-4 text-red-500">{error}</div>}

//         <div className="flex justify-center space-x-6 mb-8">
//           <button
//             onClick={() => handleTabChange("mobileAccount")}
//             className={`py-3 px-6 rounded-full font-semibold transition-colors ${
//               activeTab === "mobileAccount"
//                 ? "bg-[#72dad0] text-white"
//                 : "bg-gray-200 text-gray-600"
//             }`}
//           >
//             Mobile Account
//           </button>
//           <button
//             onClick={() => handleTabChange("bank")}
//             className={`py-3 px-6 rounded-full font-semibold transition-colors ${
//               activeTab === "bank"
//                 ? "bg-[#72dad0] text-white"
//                 : "bg-gray-200 text-gray-600"
//             }`}
//           >
//             Bank Transfer
//           </button>
//         </div>

//         {/* Mobile Account Form */}
//         {activeTab === "mobileAccount" && (
//           <form
//             onSubmit={handleMobileAccountSubmit}
//             className="w-full max-w-lg mx-auto bg-white p-8 rounded-lg shadow-md space-y-6"
//           >
//             <h2 className="text-2xl font-semibold text-[#72dad0] mb-4">
//               Mobile Account Payment
//             </h2>
//             <p>Please approve the payment from your mobile application after submission</p>
//             <select
//               name="channel_id"
//               value={mobileAccountData.channel_id}
//               onChange={handleMobileAccountChange}
//               className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#72dad0]"
//             >
//               <option value="8">Easypaisa</option>
//               <option value="10">JazzCash</option>
//             </select>
//             <input
//               type="text"
//               name="msisdn"
//               placeholder="Mobile Number"
//               value={mobileAccountData.msisdn}
//               onChange={handleMobileAccountChange}
//               required
//               className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#72dad0]"
//             />
//             <input
//               type="email"
//               name="email"
//               placeholder="Email"
//               value={mobileAccountData.email}
//               onChange={handleMobileAccountChange}
//               required
//               className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#72dad0]"
//             />
//             <input
//               type="text"
//               name="amount"
//               placeholder="Amount"
//               value={10}
//               disabled
//               className="w-full p-3 border border-gray-300 rounded-lg bg-gray-100 focus:outline-none focus:ring-2 focus:ring-[#72dad0]"
//             />
//             <button
//               type="submit"
//               className="w-full bg-[#72dad0] text-white py-3 rounded-lg font-semibold hover:bg-[#60c7b8] transition-colors"
//             >
//               Pay with Mobile Account
//             </button>
//           </form>
//         )}

//         {/* Bank Transfer Form */}
//         {activeTab === "bank" && (
//           <form
//             onSubmit={handleBankOTPSubmit}
//             className="w-full max-w-lg mx-auto bg-white p-8 rounded-lg shadow-md space-y-6"
//           >
//                 <h2 className="text-2xl font-semibold text-[#72dad0] mb-4">Bank Transfer</h2>
//             <select
//               name="bankCode"
//               value={bankData.bankCode}
//               onChange={handleBankChange}
//               required
//               className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#72dad0]"
//             >
//               <option value="">Select Bank</option>
//               {banks.map((bank) => (
//                 <option key={bank.code} value={bank.code}>
//                   {bank.name}
//                 </option>
//               ))}
//             </select>
//             <input
//               type="text"
//               name="amount"
//               placeholder="Amount"
//               value={bankData.amount}
//               disabled
//               className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#72dad0]"
//             />
//             <input
//               type="text"
//               name="msisdn"
//               placeholder="Mobile Number"
//               value={bankData.msisdn}
//               onChange={handleBankChange}
//               required
//               className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#72dad0]"
//             />
//             <input
//               type="text"
//               name="cnic"
//               placeholder="CNIC"
//               value={bankData.cnic}
//               onChange={handleBankChange}
//               className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#72dad0]"
//             />
//             <input
//               type="email"
//               name="email"
//               placeholder="Email"
//               value={bankData.email}
//               onChange={handleBankChange}
//               className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#72dad0]"
//             />
//             <input
//               type="text"
//               name="accountNo"
//               placeholder="Account No"
//               value={bankData.accountNo}
//               onChange={handleBankChange}
//               className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#72dad0]"
//             />
//             <button
//               type="submit"
//               className="w-full bg-[#72dad0] text-white py-3 rounded-lg font-semibold hover:bg-[#60c7b8] transition-colors"
//             >
//               Get OTP
//             </button>
//           </form>
//         )}
//           <Invoice isOpen={showTransactionModal} onClose={() => setShowTransactionModal(false)}>
//           <div className="max-w-2xl mx-auto bg-white  rounded-lg p-6 ">
//   <h2 className="text-2xl font-bold mb-6 text-center text-gray-800 border-b pb-4">
//     Invoice
//   </h2>
//   <div className="space-y-4">
//     <div className="flex justify-between items-center py-2 border-b">
//       <span className="text-gray-600 font-medium">Class Name:</span>
//       <span className="text-gray-900">{classDetails?.class_name}</span>
//     </div>
//     <div className="flex justify-between items-center py-2 border-b">
//       <span className="text-gray-600 font-medium">Subject:</span>
//       <span className="text-gray-900">{classDetails?.subjects}</span>
//     </div>
//     <div className="flex justify-between items-center py-2 border-b">
//       <span className="text-gray-600 font-medium">Student Name:</span>
//       <span className="text-gray-900">{classDetails?.student_name}</span>
//     </div>
//     <div className="flex justify-between items-center py-2 border-b">
//       <span className="text-gray-600 font-medium">Teacher Name:</span>
//       <span className="text-gray-900">{classDetails?.teacher_name}</span>
//     </div>
//     <div className="flex justify-between items-center py-2 border-b">
//       <span className="text-gray-600 font-medium">Payment:</span>
//       <span className="text-gray-900 font-semibold">
//         {classDetails?.payment} PKR
//       </span>
//     </div>
//     <div className="flex justify-between items-center py-2 border-b">
//       <span className="text-gray-600 font-medium">Payment Method:</span>
//       <span className="text-gray-900">{paymentMethod}</span>
//     </div>
//     <div className="flex justify-between items-center py-2 border-t">
//       <span className="text-gray-600 font-medium">Payment Status:</span>
//       <span className="text-green-600 font-semibold">Success</span>
//     </div>
//   </div>

// </div>

//       </Invoice>


//         {/* OTP Modal */}
//         {showOTPModal && (
//           <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-30">
//             <div className="bg-white rounded-lg p-8 shadow-lg w-full max-w-md">
//               <h2 className="text-2xl font-semibold text-[#72dad0] mb-4">
//                 Enter OTP
//               </h2>
//               <form onSubmit={handleBankTransferSubmit} className="space-y-4">
//                 <input
//                   type="text"
//                   name="otp"
//                   placeholder="OTP"
//                   value={transferData.otp}
//                   onChange={handleTransferChange}
//                   required
//                   className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#72dad0]"
//                 />
//                 <div className="flex gap-4">
//                   <button
//                     type="submit"
//                     className="w-full bg-green-500 text-white py-3 rounded-lg font-semibold hover:bg-green-600 transition-colors"
//                   >
//                     Confirm Transfer
//                   </button>
//                   <button
//                     type="button"
//                     onClick={() => setShowOTPModal(false)}
//                     className="w-full bg-red-500 text-white py-3 rounded-lg font-semibold hover:bg-red-600 transition-colors"
//                   >
//                     Cancel
//                   </button>
//                 </div>
//               </form>
              
//             </div>
//             <div>
          
//             </div>
//           </div>
          
//         )}
//       </div>
//     </div>
//   );
// }

// export default PaymentPage;


// import React from "react";
// import { useLocation } from "react-router-dom";

// const PaymentPage = () => {
//     const query = new URLSearchParams(useLocation().search);

//     const status = query.get("Status");
//     const amount = query.get("Amount");
//     const transactionId = query.get("CustomerTransactionId");
//     const orderId = query.get("OrderId");
//     const paymentType = query.get("PaymentType");

//     return (
//         <div>
//             <h1>Payment Status: {status}</h1>
//             <p>Amount Paid: {amount}</p>
//             <p>Transaction ID: {transactionId}</p>
//             <p>Order ID: {orderId}</p>
//             <p>Payment Method: {paymentType}</p>
//         </div>
//     );
// };

// export default PaymentPage;


// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import apiKey from "../../../config/apiKey";

// const InvoicePage = () => {
//   const [invoiceData, setInvoiceData] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   // Get student token and ID from localStorage
//   const student = JSON.parse(localStorage.getItem("edo-student"));
  
//   useEffect(() => {
//     const fetchInvoiceData = async () => {
//       try {
//         const response = await axios.get(
//           `${apiKey}/api/edolutions/student/transactiondetails/${student?.id}`,
//           {
//             headers: {
//               Authorization: `Bearer ${student?.token}`,
//             },
//           }
//         );
//         setInvoiceData(response.data);
//         setLoading(false);
//       } catch (err) {
//         setError("Failed to fetch invoice data.");
//         setLoading(false);
//       }
//     };

//     fetchInvoiceData();
//   }, [student]);

//   if (loading) {
//     return <div className="text-center text-lg text-gray-500">Loading...</div>;
//   }

//   if (error) {
//     return (
//       <div className="text-center text-red-500 text-lg font-semibold">
//         {error}
//       </div>
//     );
//   }

//   const {
//     class_details: {
//       teacher_name,
//       student_name,
//       class_name,
//       subjects,
//       type_name,
//       payment,
//       transaction_id,
//     },
//   } = invoiceData;

//   return (
//     <div className="max-w-4xl mx-auto p-6 bg-gray-100 rounded-lg shadow-md mt-10">
//       {/* Success Banner */}
//       <div className="bg-green-500 text-white p-6 rounded-lg text-center mb-6">
//         <h1 className="text-2xl font-bold">Payment Successful!</h1>
//         <p className="text-lg">Thank you for your payment, {student_name}.</p>
//       </div>

//       {/* Invoice Section */}
//       <div className="bg-white p-6 rounded-lg shadow-lg">
//         <h2 className="text-xl font-semibold border-b-2 border-green-500 pb-2 mb-4">
//           Invoice Details
//         </h2>
//         <div className="space-y-4">
//           <div className="flex justify-between">
//             <span className="font-semibold">Transaction ID:</span>
//             <span>{transaction_id}</span>
//           </div>
//           <div className="flex justify-between">
//             <span className="font-semibold">Amount Paid:</span>
//             <span>${payment}</span>
//           </div>
//           <div className="flex justify-between">
//             <span className="font-semibold">Class:</span>
//             <span>{class_name}</span>
//           </div>
//           <div className="flex justify-between">
//             <span className="font-semibold">Subject:</span>
//             <span>{subjects}</span>
//           </div>
//           <div className="flex justify-between">
//             <span className="font-semibold">Teacher:</span>
//             <span>{teacher_name}</span>
//           </div>
//           <div className="flex justify-between">
//             <span className="font-semibold">Type:</span>
//             <span>{type_name}</span>
//           </div>
//         </div>
//       </div>

//       {/* Footer Note */}
//       <div className="text-center text-gray-500 text-sm mt-6">
//         <p>
//           If you have any questions, feel free to contact us at{" "}
//           <a
//             href="mailto:support@edolutions.com"
//             className="text-green-500 underline"
//           >
//             support@edolutions.com
//           </a>
//           .
//         </p>
//       </div>
//     </div>
//   );
// };

// export default InvoicePage;
import React, { useEffect, useState } from "react";
import axios from "axios";
import apiKey from "../../../config/apiKey";

const InvoicePage = () => {
  const [invoiceData, setInvoiceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const storedData = JSON.parse(localStorage.getItem("edo-student"));
  const id = storedData?.student?.id;
  const token = storedData?.token;

  useEffect(() => {
    const fetchInvoiceData = async () => {
      if (!id || !token) {
        setError("Student information is missing or invalid.");
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(
          `${apiKey}/api/edolutions/student/transactiondetails/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setInvoiceData(response.data);
      } catch (err) {
        setError("Failed to fetch invoice data.");
      } finally {
        setLoading(false);
      }
    };

    fetchInvoiceData();
  }, [id, token]);

  if (loading) {
    return <div className="text-center text-lg text-gray-500">Loading...</div>;
  }

  if (error) {
    return (
      <div className="text-center text-red-500 text-lg font-semibold">
        {error}
      </div>
    );
  }

  const {
    class_details: {
      teacher_name,
      student_name,
      class_name,
      subjects,
      type_name,
      days,
      duration,
    },
    transaction: { order_id, amount },
  } = invoiceData;

  return (
    <div className="max-w-4xl mx-auto p-6 bg-gray-100 rounded-lg shadow-md mt-10">
      <div className="bg-green-500 text-white p-6 rounded-lg text-center mb-6">
        <h1 className="text-2xl font-bold">Payment Successful!</h1>
        <p className="text-lg">Thank you for your payment, {student_name}.</p>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-xl font-semibold border-b-2 border-green-500 pb-2 mb-4">
          Invoice Details
        </h2>
        <div className="space-y-4">
          <div className="flex justify-between">
            <span className="font-semibold">Transaction ID:</span>
            <span>{order_id}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-semibold">Amount Paid:</span>
            <span>Rs. {amount}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-semibold">Class:</span>
            <span>{class_name}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-semibold">Subject:</span>
            <span>{subjects}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-semibold">Teacher:</span>
            <span>{teacher_name}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-semibold">Sessions:</span>
            <span>
              {days} days {duration}
            </span>
          </div>
        </div>
      </div>

      <div className="text-center text-gray-500 text-sm mt-6"></div>
    </div>
  );
};

export default InvoicePage;

