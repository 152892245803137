import React, { useState } from "react";
import axios from "axios";
import apiKey from "../../../config/apiKey";

const PayNow = ({ stdId,classId, item, amount, payeeName, email, msisdn, description }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handlePayment = async () => {
        setIsLoading(true);

        try {
            const response = await axios.post(`${apiKey}/api/edolutions/generate-payin-url`, {
                class_id: classId,
                student_id: stdId,
                item: item || "Test Item",
                amount: amount || 10,
                payeeName: payeeName || "Test User",
                email: email || "test@example.com",
                msisdn: msisdn || "03001234567",
                description: description || "Test Transaction",
            });

            if (response.data.url) {
                window.location.href = response.data.url; // Redirect to the generated URL
            }
        } catch (error) {
            console.error("Error generating payment URL:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <button className="bg-red-500 text-sm text-white px-4 py-2 rounded-md" onClick={handlePayment} disabled={isLoading}>
                {isLoading ? "Redirecting..." : "Pay Fee"}
            </button>
        </div>
    );
};

export default PayNow;
