import React from 'react'
import DataTable2 from '../../../components/AdminTable/DataTable2';

function Report() {
  const header=[{
    id:1,
    thead1:"Teacher Name",
    thead2: "Subject",
    thead3:"Total Classes",
    thead5:"Status",
    
  }]

  const data = [
    {
      id: 1,
      column1: "Irfan Ahsan",
      column2: "Subject Name",
      column3: "10",
      column4: "---",
    },
    {
      id: 2,
      column1: "Muslim Sheikh",
      column2: "Subject Name",
      column3: "5",
      column4: "---",
      
    },
    {
      id: 3,
      column1: "Muhammad Sohail",
      column2: "Subject Name",
      column3: "2",
      column4: "---",
      
    },
    {
      id: 4,
      column1: "Zohaib Bhai",
      column2: "Subject Name",
      column3: "9",
      column4: "---",
   
    },
    {
      id: 5,
      column1: "Muslim Sheikh",
      column2: "Subject Name",
      column3: "10",
      column4: "---",
     
    },
   
    
  ];



  return (
    <main>
      <DataTable2 
      heading={'Reports'}
      SubHeading={'Teachers'}
      header={header}
      data={data}/>

      
    </main>
  )
}

export default Report
