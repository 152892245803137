import React from "react";

const PaymentFailedPage = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-gray-100 rounded-lg shadow-md mt-10">
      {/* Failure Banner */}
      <div className="bg-red-500 text-white p-6 rounded-lg text-center mb-6">
        <h1 className="text-2xl font-bold">Payment Failed</h1>
        <p className="text-lg">
          Unfortunately, your payment could not be processed at this time.
        </p>
      </div>

      {/* Suggested Actions */}
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-xl font-semibold border-b-2 border-red-500 pb-2 mb-4">
          What Can You Do Next?
        </h2>
        <ul className="list-disc list-inside space-y-2 text-gray-700">
          <li>
            Check your payment details and try again. Ensure your card or
            payment method has sufficient balance.
          </li>
          <li>
            Contact your bank or payment provider for more information.
          </li>
          <li>
            If you continue to face issues, reach out to our support team for
            assistance.
          </li>
        </ul>
      </div>

      {/* Footer Note */}
      <div className="text-center text-gray-500 text-sm mt-6">
        {/* <p>
          Need help? Contact us at{" "}
          <a
            href="mailto:support@edolutions.com"
            className="text-red-500 underline"
          >
            support@edolutions.com
          </a>{" "}
          or try making the payment again.
        </p> */}
      </div>
    </div>
  );
};

export default PaymentFailedPage;
