import React, { useState } from "react";
import { FiSearch } from "react-icons/fi";
import { Link } from "react-router-dom";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

export default function DataTable2({ heading, SubHeading, header, data }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const totalItems = data.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(Math.min(Math.max(1, pageNumber), totalPages));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleItemsPerPageChange = (event) => {
    const selectedItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalItems);

  return (
    <div className="relative my-8 flex flex-col min-w-0 break-words w-full mb-6 shadow-lg bg-white rounded-lg p-8 border-0">
      <div className="flex flex-wrap items-center justify-between">
        <div>
          <p className="text-[22px] font-bold ">{heading}</p>
          <p className="text-[14px] font-normal text-[#4FD1C5] mt-2">
            {SubHeading}
          </p>
        </div>

        <div className="flex items-center">
          <div className="relative border shadow-md rounded-md flex items-center  px-5 py-2">
            <FiSearch className="h-5 w-5 absolute  text-[#5963F5]" />{" "}
            <input
              type="text"
              className="outline-none pl-8"
              placeholder="Search"
              name=""
              id=""
            />
          </div>
          <div className="relative ml-2">
            <select
              name=""
              className="border shadow-md rounded-md  px-5 py-2 outline-none pl-2 pr-8 text-sm"
            >
              <option value="">Sorted By : Newest </option>
              <option value="">Sorted By : Oldest </option>
              <option value="">Sorted By : Teachers </option>
              <option value="">Sorted By : One to One </option>
            </select>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <table className="w-full text-sm text-left text-gray-500 border-b table-auto">
          <thead className="text-sm text-[#B5B7C0] font-medium border-b">
            {header.map((items) => (
              <tr key={items.id} className="border-b">
                <td className="py-5 px-1">{items.thead1}</td>
                <td className="py-5 px-1">{items.thead2}</td>
                <td className="py-5 px-1">{items.thead3}</td>
                <td className="py-5 px-1">{items.thead4}</td>
                <td className="py-5 px-1 ">{items.thead5}</td>
              </tr>
            ))}
          </thead>
          <tbody className="text-black text-sm font-medium ">
            {data.slice(startIndex, endIndex).map((item) => (
              <tr key={item.id} className="border-b">
                <td className="py-5 px-1">
                  <Link to={item.path}>{item.column1}</Link>
                </td>
                <td className="py-5 px-1">{item.column2}</td>
                <td className="py-5 px-1">{item.column3}</td>
                <td className="py-5 px-1 "></td>
                <td className="py-5 px-1 ">
                  <p
                    className={`border cursor-pointer shadow-lg px-5 rounded-md py-1 w-fit ${
                      item.column4 === "Inactive"
                        ? "text-red-500"
                        : item.column4 === "Ongoing"
                        ? "text-green-500"
                        : ""
                    }`}
                  >
                    {item.column4}
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="flex flex-wrap gap-5 justify-between items-center w-full text-sm mt-4 pb-4 px-2">
          <div className="flex items-center gap-x-2 text-gray-400">
            <span>
              <select
                className="text-sm border-none text-black bg-[#F5F5F5] rounded-lg"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
              >
                {[10, 20, 30, 40, 50, 100].map((perPage) => (
                  <option key={perPage} value={perPage}>
                    {perPage}
                  </option>
                ))}
              </select>
            </span>
            <span>Showing Data</span>
            <span>
              {startIndex + 1}-{endIndex} of {totalItems} Entries
            </span>
          </div>
          <div className="flex items-center gap-x-2 text-gray-400">
            <button
              className="text-base text-black bg-[#F5F5F5] rounded-lg px-2 py-1"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              <MdKeyboardArrowLeft />
            </button>
            <div className="flex items-center gap-x-2 text-gray-400">
              {[...Array(Math.min(5, totalPages || 1))].map((_, index) => {
                const pageNumber = currentPage + index;
                const isActive = pageNumber === currentPage;
                return (
                  <button
                    key={index}
                    className={`text-base rounded-lg px-3 py-1 ${
                      isActive
                        ? "text-white bg-[#4FD1C5]"
                        : "text-black bg-[#F5F5F5]"
                    }`}
                    onClick={() => handlePageChange(pageNumber)}
                    disabled={pageNumber < 1 || pageNumber > totalPages}
                  >
                    {pageNumber}
                  </button>
                );
              })}
              <span> of {totalPages || 1} pages</span>
            </div>
            <button
              className="text-base text-black bg-[#F5F5F5] rounded-lg px-2 py-1"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <MdKeyboardArrowRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
